'use strict'

###*
 # @ngdoc object
 # @name mundoComponents
 # @description

###
angular
  .module 'mundoComponents', ['ui.router']
  .run ['Restangular', '$log', '$rootScope', (Restangular, $log, $rootScope) ->

    Restangular.addFullRequestInterceptor (element, operation, route, url, headers, params, httpConfig)->
      if operation is "post"
        if not element.tenant? and headers.autoTenant is true
          tenantId = $rootScope.user.activeUserContext.tenant.id
          element.tenant = tenantId
      return {
        element: element
        headers: headers
        params: params
        httpConfig: httpConfig
      }
  ]
